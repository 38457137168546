import React, { useEffect, useState } from 'react'
import axios from 'axios'
import style from './ApplyingList.module.scss'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import adminStyles from '../AdminManageLayout.module.scss'
import _ from 'lodash'

export default function CreateApply({ isEdit = false }) {
  const auth = useSelector((state) => state.admin.auth)
  const { applyingId } = useParams()
  const [universities, setUniversities] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: '',
    desc: '',
    universityName: '',
  })
  const history = useHistory()
  const [status, setStatus] = useState({})

  useEffect(() => {
    const initCreateApplying = async () => {
      let universitieslist = []
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/admin/universities`,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
        .then(({ data }) => {
          universitieslist = data
          setUniversities(data)
        })
        .catch((error) => {
          console.error(error)
        })
      if (isEdit) {
        await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/admin/applying/${applyingId}`,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        })
          .then(({ data }) => {
            setInitialValues({
              ...data,
              Major: data.major,
              universityName: _.find(universitieslist, { id: data.universityId }).name,
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
    initCreateApplying()
    return () => {}
  }, [applyingId, auth.token, isEdit])

  useEffect(() => {
    if (isEdit) {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/admin/applying/${applyingId}`,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
        .then(({ data }) => {
          setInitialValues(data)
          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    return () => {}
  }, [applyingId, auth.token, isEdit])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setStatus({
        buttonText: 'SENDING ...',
        desc: 'กำลังส่ง...',
      })
      axios({
        url: isEdit
          ? `${process.env.REACT_APP_API_URL}/admin/applying/${applyingId}`
          : `${process.env.REACT_APP_API_URL}/admin/applying/new`,
        method: 'POST',
        timeout: 5000,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
        data: _.pickBy({
          ...formik.values,
          universityId: universities.find((university) => university.name === formik.values.universityName)
            ?.id,
        }),
      })
        .then((response) => {
          setStatus({
            buttonText: 'SUCCESS',
            desc: `เสร็จสิ้น ${new Date().toLocaleString()}`,
          })
          return history.push('/admin/applying')
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED') {
            return setStatus({
              buttonText: 'CANNOT CONNECT',
              desc: 'กรุณาตรวจสอบการเชื่อมต่อ',
            })
          }
          return setStatus({
            buttonText: 'NOT SUCCESS',
            desc: 'ไม่สำเร็จ',
          })
        })
    },
  })

  return (
    <React.Fragment>
      <div className={style.cover}>
        <div className={style.wrapper}>
          <div className={style.headingRow}>
            <h1>New Apply</h1>
            <Link to='/admin/applying' className={adminStyles.addNewBlog}>
              กลับ
            </Link>
          </div>
          <form onSubmit={formik.handleSubmit} className={style.courseForm}>
            <div className={style.coverInput}>
              <label className={style.headInput}>ชื่อ</label>
              <input
                id='name'
                name='name'
                type='text'
                placeholder='ชื่อข้อมูล'
                required
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>คำอธิบาย</label>
              <input
                id='desc'
                name='desc'
                type='text'
                placeholder='เช่น 1 กุมภาพันธ์ 2565'
                required
                onChange={formik.handleChange}
                value={formik.values.desc}
              />
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>มหาวิทยาลัย</label>
              <input
                id='univerisityName'
                name='universityName'
                list='universityNames'
                placeholder='เลือกมหาวิทยาลัย'
                onChange={formik.handleChange}
                value={formik.values.universityName}
                required
              />
            </div>
            <datalist
              id='universityNames'
              name='universityNames'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.universityId}
              required
            >
              {universities.map((university) => (
                <option key={university.id} value={university.name} />
              ))}
            </datalist>
            <button type='submit' className={adminStyles.addNewBlog}>
              {status?.desc || 'ยืนยัน'}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
