import { Table, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import adminStyles from '../AdminManageLayout.module.scss'
import axios from 'axios'

function ChineseScoreList() {
  const auth = useSelector((state) => state.admin.auth)

  const columns = [
    ...[
      ['ชื่อ', 'name'],
      ['เลเวล', 'level'],
      ['การกระทำ', 'operator'],
      ['คะแนน', 'score'],
    ].map(([title, key]) => ({
      title,
      key,
      dataIndex: key,
      align: 'center',
    })),
    {
      title: 'คำสั่ง',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size='middle'>
          <Link
            to={`/admin/chinese-score/edit?name=${record.name}&operator=${record.operator}&score=${record.score}&level=${record.level}`}
          >
            <EditOutlined />
            &nbsp; แก้ไข
          </Link>
          <a
            onClick={() => {
              deleteScore(record)
            }}
          >
            <DeleteOutlined />
            &nbsp; ลบ
          </a>
        </Space>
      ),
    },
  ]
  const [dataArray, setDataArray] = useState([])
  const deleteScore = async (record) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API_URL}/admin/chinese-score?name=${record.name}&operator=${record.operator}&score=${record.score}&level=${record.level}`,
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
      data: '',
    })
    const { name } = _.get(result, 'data')
    if (name) {
      const newData = dataArray.filter((item) => {
        return (
          item.name !== record.name ||
          item.score !== record.score ||
          item.operator !== record.operator ||
          item.level !== record.level
        )
      })
      setDataArray(newData)
    }
  }
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/chinese-score`,
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
    })
      .then(({ data }) => {
        setDataArray(data.map((item) => ({ ...item, universityName: item.university?.name || 'ไม่ได้ระบุ' })))
      })
      .catch((error) => {
        console.error(error)
      })
    return () => {}
  }, [auth.token])

  return (
    <React.Fragment>
      <div className={adminStyles.coverLayout}>
        <div className={adminStyles.header}>
          <h1>Chinese Score</h1>
          <div className={adminStyles.btnList}>
            <Link to='/admin/chinese-score/new' className={adminStyles.addNewBlog}>
              + เพิ่ม
            </Link>
          </div>
        </div>
        <Table dataSource={dataArray} columns={columns} rowKey='id' />
      </div>
    </React.Fragment>
  )
}

export default ChineseScoreList
