import { Table, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import adminStyles from '../AdminManageLayout.module.scss'
import axios from 'axios'

function ConfigList() {
  const auth = useSelector((state) => state.admin.auth)

  const columns = [
    {
      title: 'ชื่อ',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'ค่า',
      key: 'desc',
      dataIndex: 'desc',
      align: 'left',
    },
    {
      title: 'คำสั่ง',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size='middle'>
          <Link to={`/admin/config/edit/${record.id}`}>
            <EditOutlined />
            &nbsp; แก้ไข
          </Link>
          <a
            onClick={() => {
              deleteApply(record.id)
            }}
          >
            <DeleteOutlined />
            &nbsp; ลบ
          </a>
        </Space>
      ),
    },
  ]
  const [dataArray, setDataArray] = useState([])
  const deleteApply = async (configId) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API_URL}/admin/config/${configId}`,
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
      data: '',
    })
    const id = _.get(result, 'data.id')
    if (id) {
      const newData = dataArray.filter((item) => item.id !== id)
      setDataArray(newData)
    }
  }
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/config`,
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
    })
      .then(({ data }) => {
        setDataArray(
          data.map((item) => ({
            ...item,
            universityName: item.university?.name || 'ไม่ได้ระบุ',
            value: item.value2 ? `${item.value1} - ${item.value2}` : item.value1,
          }))
        )
      })
      .catch((error) => {
        console.error(error)
      })
    return () => {}
  }, [auth.token])

  return (
    <React.Fragment>
      <div className={adminStyles.coverLayout}>
        <div className={adminStyles.header}>
          <h1>Setting</h1>
          <div className={adminStyles.btnList}>
            <Link to='/admin/config/new' className={adminStyles.addNewBlog}>
              + เพิ่ม
            </Link>
          </div>
        </div>
        <Table dataSource={dataArray} columns={columns} rowKey='id' />
      </div>
    </React.Fragment>
  )
}

export default ConfigList
