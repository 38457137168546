import { combineReducers } from 'redux'
import { adminSlice } from './Admin.slice'
import { courseSlice } from './course.slice'
import { questionSlice } from './question.slice'

export const rootReducers = combineReducers({
  admin: adminSlice.reducer,
  course: courseSlice.reducer,
  question: questionSlice.reducer,
})
