import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import axios from 'axios'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/configureStore'
import 'sweetalert2/src/sweetalert2.scss'

// DEVELOPMENT BUILD
process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV_NAME === 'development' &&
  console.log('[INFO] Application running on development build.')
// PRODUCTION BUILD
process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV_NAME === 'production' &&
  console.log('[INFO] Application running on production build.')
// DEVELOPMENT
process.env.NODE_ENV === 'development' &&
  console.log('[INFO] Application running on development build.', process.env)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
console.log('VERSION', 2);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)