import React, { Suspense, lazy, useEffect } from 'react'
import { Router, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { PrivateRoute } from '../helpers/PrivateRoute'
import { HelmetProvider } from 'react-helmet-async'
import AppHelmet from './App.helmet'

import history from './history'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import './App.scss'
import style from './AppStyle.module.scss'
import './react-transition.css'
import 'antd/dist/antd.css'
import { Spin, Space } from 'antd'

import LoginAdmin from '../Admin/AdminLogin'
import Navigation from '../Admin/component/Navigation'
import AddCountry from '../Admin/AddCountry'
import CreateCountry from '../Admin/AddCountry/CreateCountry'
import ApplyingList from '../Admin/Applying/ApplyingList'
import CreateApply from '../Admin/Applying/CreateApply'
import CreateScholarship from '../Admin/Scholarship/CreateScholarship'
import ScholarshipList from '../Admin/Scholarship/ScholarshipList'
import CostList from '../Admin/Cost/CostList'
import CreateCost from '../Admin/Cost/CreateCost'
import EnglishScore from '../Admin/EnglishScore/EnglishScoreList'
import CreateEnglishScore from '../Admin/EnglishScore/CreateEnglishScore'
import ChineseScoreList from '../Admin/ChineseScore/ChineseScoreList'
import CreateChineseScore from '../Admin/ChineseScore/CreateChineseScore'
import Questionnaire from '../Admin/QuestionnaireList'
import FetchQuestionnaire from '../Admin/Questionnaire'
import ConfigList from '../Admin/Config/ConfigList'
import CreateConfig from '../Admin/Config/CreateConfig'
// import BlogCreate from '../Admin/CreateBlog'
// import BlogEdit from '../Blog/BlogEdit'
const AdminCreateCountry = lazy(() => import('../Admin/AddCountry/CreateCountry'))
const AdminReview = lazy(() => import('../Admin/AdminReview'))
const AdminListBlogs = lazy(() => import('../Admin/AdminListBlogs'))
const AdminListLesson = lazy(() => import('../Admin/AdminListLesson'))
const AdminListUser = lazy(() => import('../Admin/AdminListUser'))
const AdminListMajors = lazy(() => import('../Admin/AdminListMajors'))
const AdminCreateMajor = lazy(() => import('../Admin/AdminCreate/CreateMajor'))
const AdminProgram = lazy(() => import('../Admin/AdminListProgram.js'))
const AdminCreateProgram = lazy(() => import('../Admin/Program/CreateProgram'))
const AdminImportProgram = lazy(() => import('../Admin/Program/ImportProgram'))
// ADMIN
const AdminCreateBilling = lazy(() => import('../Admin/AdminCreate/CreateBilling'))
const CreateCourse = lazy(() => import('../Admin/AdminCreate/ManageCourse/CreateCourse'))
const ManageCourse = lazy(() => import('../Admin/AdminCreate/ManageCourse/ManageCourse'))
const CreateApplication = lazy(() => import('../Admin/AdminCreate/CreateApplication'))
const AdminListCourse = lazy(() => import('../Admin/AdminListCourse'))
const AdminUniversityListing = lazy(() => import('../Admin/AdminUniversityListing'))
const ImportUniversity = lazy(() => import('../Admin/CreateUniversity/ImportUniversity'))
const AdminCreateUniversity = lazy(() => import('../Admin/CreateUniversity'))
const AdminListBilling = lazy(() => import('../Admin/AdminListBilling.js'))
const AdminListApplication = lazy(() => import('../Admin/AdminListApplication.js'))
const AdminCreateBlog = lazy(() => import('../Admin/CreateBlog'))
const AdminListBanner = lazy(() => import('../Admin/AdminListBanner.js'))
const AdminCreateBanner = lazy(() => import('../Admin/AdminCreate/CreateBanner'))
const AdminCreateAdmin = lazy(() => import('../Admin/AdminCreate/CreateAdmin'))
const AdminListAdmin = lazy(() => import('../Admin/AdminListAdmin.js'))

const TransitionSwitch = ({ children }) => {
  const location = useLocation()
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames='fade' timeout={300}>
        <Suspense
          fallback={
            <div className={style.spinnerWrapper}>
              <Space size='middle' className={style.spinner}>
                <Spin size='large' />
              </Space>
            </div>
          }
        >
          <Switch location={location}>{children}</Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  )
}

function App() {
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0)) // Scroll to top!
    return () => {
      unlisten && unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return (
    <HelmetProvider>
      <AppHelmet />
      <Router history={history}>
        <Switch>
          <Route exact path='/admin/login' component={LoginAdmin} />
          <Route path='/'>
            <div className={style.layoutAdmin}>
              <Navigation />
              <div className={style.coverLayout}>
                <TransitionSwitch>
                  <PrivateRoute exact path='/admin/university' component={AdminUniversityListing} />
                  <PrivateRoute exact path='/admin/university/new' component={AdminCreateUniversity} />
                  <PrivateRoute exact path='/admin/university/import' component={ImportUniversity} />

                  <PrivateRoute exact path='/admin/program' component={AdminProgram} />
                  <PrivateRoute exact path='/admin/program/new' component={AdminCreateProgram} />
                  <PrivateRoute exact path='/admin/program/import' component={AdminImportProgram} />
                  <PrivateRoute exact path='/admin/program/edit/:programId'>
                    <AdminCreateProgram isEdit />
                  </PrivateRoute>

                  <PrivateRoute exact path='/admin/majors' component={AdminListMajors} />
                  <PrivateRoute exact path='/admin/majors/new' component={AdminCreateMajor} />
                  <PrivateRoute exact path='/admin/majors/edit/:majorId'>
                    <AdminCreateMajor isEdit />
                  </PrivateRoute>

                  <PrivateRoute exact path='/admin/university/edit/:universityId'>
                    <AdminCreateUniversity isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/courses' component={AdminListCourse} />
                  <PrivateRoute exact path='/admin/courses/new' component={CreateCourse} />
                  <PrivateRoute exact path='/admin/courses/edit/:courseId'>
                    <CreateCourse isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/courses/manage/:courseId'>
                    <ManageCourse isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/applications' component={AdminListApplication} />
                  <PrivateRoute exact path='/admin/applications/new' component={CreateApplication} />
                  <PrivateRoute exact path='/admin/applications/edit/:applicationId'>
                    <CreateApplication isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/lessons' component={AdminListLesson} />

                  <PrivateRoute exact path='/admin/blogs' component={AdminListBlogs} />
                  <PrivateRoute path='/admin/blog/new' component={AdminCreateBlog} />
                  <PrivateRoute path='/admin/blog/edit/:blogId'>
                    <AdminCreateBlog isEdit />
                  </PrivateRoute>

                  <PrivateRoute exact path='/admin/homepic' component={AdminListBanner} />
                  <PrivateRoute path='/admin/homepic/new' component={AdminCreateBanner} />
                  <PrivateRoute path='/admin/homepic/edit/:bannerId'>
                    <AdminCreateBanner isEdit />
                  </PrivateRoute>

                  <PrivateRoute exact path='/admin/billings' component={AdminListBilling} />
                  <PrivateRoute exact path='/admin/billings/new' component={AdminCreateBilling} />
                  <PrivateRoute path='/admin/billing/edit/:billingId'>
                    <AdminCreateBilling isEdit />
                  </PrivateRoute>

                  <PrivateRoute exact path='/admin/users' component={AdminListUser} />
                  <PrivateRoute exact path='/admin/reviews' component={AdminReview} />

                  <PrivateRoute exact path='/admin/managements/listadmin' component={AdminListAdmin} />
                  <PrivateRoute exact path='/admin/managements/new' component={AdminCreateAdmin} />
                  <PrivateRoute exact path='/admin/countries/new' component={AdminCreateCountry} />
                  <PrivateRoute exact path='/admin/countries' component={AddCountry} />
                  <PrivateRoute path='/admin/countries/edit/:countryId'>
                    <CreateCountry isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/applying' component={ApplyingList} />
                  <PrivateRoute exact path='/admin/applying/new' component={CreateApply} />
                  <PrivateRoute exact path='/admin/applying/edit/:applyingId'>
                    <CreateApply isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/scholarship' component={ScholarshipList} />
                  <PrivateRoute exact path='/admin/scholarship/new' component={CreateScholarship} />
                  <PrivateRoute exact path='/admin/scholarship/edit/:scholarshipId'>
                    <CreateScholarship isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/cost' component={CostList} />
                  <PrivateRoute exact path='/admin/cost/new' component={CreateCost} />
                  <PrivateRoute exact path='/admin/cost/edit/:costId'>
                    <CreateCost isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/english-score' component={EnglishScore} />
                  <PrivateRoute exact path='/admin/english-score/new' component={CreateEnglishScore} />
                  <PrivateRoute exact path='/admin/english-score/edit/'>
                    <CreateEnglishScore isEdit />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path='/admin/questionnaire/:questionnaireId'
                    component={FetchQuestionnaire}
                  />
                  <PrivateRoute exact path='/admin/questionnaire' component={Questionnaire} />
                  <PrivateRoute exact path='/admin/chinese-score' component={ChineseScoreList} />
                  <PrivateRoute exact path='/admin/chinese-score/new' component={CreateChineseScore} />
                  <PrivateRoute exact path='/admin/chinese-score/edit'>
                    <CreateChineseScore isEdit />
                  </PrivateRoute>
                  <PrivateRoute exact path='/admin/config' component={ConfigList} />
                  <PrivateRoute exact path='/admin/config/new' component={CreateConfig} />
                  <PrivateRoute exact path='/admin/config/edit/:configId'>
                    <CreateConfig isEdit />
                  </PrivateRoute>
                  <Redirect to='/admin/university' />
                </TransitionSwitch>
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    </HelmetProvider>
  )
}

export default App
