import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const adminFetchQuestion = createAsyncThunk(
  'question/adminFetchQuestion ',
  async (__unused, { getState }) => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/questions`,
      headers: {
        Authorization: 'Bearer ' + getState().admin?.auth?.token,
      },
    })

    return _.get(res, 'data', null)
  }
)

export const questionSlice = createSlice({
  name: 'question',
  initialState: {
    question: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  reducers: {
    applyFilter: (state) => state,
  },
  extraReducers: {
    [adminFetchQuestion.fulfilled]: (state, action) => {
      state.question = action.payload
    },
  },
})
