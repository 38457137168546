import React, { useState, useEffect } from 'react'
import style from './Navigation.module.scss'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../redux/Admin.slice'

const activeStyle = { borderBottom: '3px solid #dd9595', color: '#595959' }

const _routes = [
  ['/admin/university', 'Universities'],
  ['/admin/program', 'Programs'],
  ['/admin/majors', 'Majors'],
  ['/admin/courses', 'Courses'],
  ['/admin/users', 'Users'],
  ['/admin/billings', 'Billing'],
  ['/admin/blogs', 'Blog'],
  ['/admin/reviews', 'Review'],
  ['/admin/homepic', 'Banner'],
  ['/admin/applications', 'Application'],
  ['/admin/countries', 'Country'],
  // ['/admin/applying', 'Apply'],
  // ['/admin/scholarship', 'Scholarship'],
  // ['/admin/cost', 'Cost'],
  ['/admin/english-score', 'English Score'],
  ['/admin/chinese-score', 'Chinese Score'],
  ['/admin/questionnaire', 'Questionnaire'],
  ['/admin/config', 'Settings'],
]

const superAdminRoutes = [['/admin/managements/listadmin', 'Admin']]

function NavAdmin() {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state) => state.admin.auth)
  const [routes, setRoutes] = useState(_routes)

  useEffect(() => {
    if (auth) {
      if (auth.role === 'superadmin') {
        setRoutes((prev) => {
          return [...prev, ...superAdminRoutes]
        })
      }
    }
  }, [auth])

  const handleLogout = () => {
    dispatch(logout())
    localStorage.removeItem('token')
    history.push('/admin/login')
  }

  return (
    <div className={`${style.backGround}`}>
      <div className={`${style.wrapper} ${style.navLink}`}>
        <div className={style.start}>
          <div className={style.pic}>
            <Link to='/'>
              <img src={require('../assets/CASC-a1 1.png')} alt='casc-logo' />
            </Link>
          </div>
        </div>
        <div className={style.profile}>
          <div className={style.nameRole}>
            {auth?.firstName !== null && (
              <div className={style.name}>
                Name : {auth?.firstName} {auth?.lastName}
              </div>
            )}
            {auth?.role !== null && <div className={style.role}>Role : {auth?.role}</div>}
          </div>
        </div>
        <div className={style.mid}>
          {routes?.map(([link, text]) => (
            <NavLink key={link} to={link} activeStyle={activeStyle}>
              {text}
            </NavLink>
          ))}
        </div>
        <div className={style.end}>
          <button onClick={() => handleLogout()}>LOG OUT</button>
        </div>
      </div>
    </div>
  )
}

export default NavAdmin
