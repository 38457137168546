import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import style from './AdminLogin.module.scss'
import gStyle from '../../global.module.scss'
import { useFormik } from 'formik'
import picture from './asset/The-Chinese-University-of-Hong-Kong-CUHK.jpg'
import logo from './asset/CASC-a1 1.png'
import { login } from '../../redux/Admin.slice.js'

export default function Login() {
  const history = useHistory()
  const auth = useSelector((state) => state.admin.auth)
  const authError = useSelector((state) => state.admin.authError)
  const dispatch = useDispatch()

  const handleSubmit = (email, password) => {
    dispatch(login(email, password))
  }

  useEffect(() => {
    if (auth) {
      setStatus({
        buttonText: 'SUCCESS',
        desc: `Success on ${new Date().toLocaleString()}`,
      })
      history.push('/admin/university')
    }

    //eslint-disable-next-line
  }, [auth])

  useEffect(() => {
    if (authError) {
      if (authError.code === 'ECONNABORTED') {
        return setStatus({
          buttonText: 'CANNOT CONNECT',
          desc: '',
        })
      }
      return setStatus({
        buttonText: 'NOT SUCCESS',
        desc: 'ไม่สำเร็จ',
      })
    }

    //eslint-disable-next-line
  }, [authError])

  let [status, setStatus] = useState({
    buttonText: 'SUBMIT',
    desc: '',
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setStatus({
        buttonText: 'SENDING ...',
        desc: '',
      })
      handleSubmit(values)
    },
  })

  return (
    <div className={style.root}>
      <div className={style.app}>
        <div className={style.wrapper}>
          <div className={style.container}>
            <div className={style.cover}>
              <img src={picture} alt='side-banner-login' />
            </div>
            <div className={style.content}>
              <form className={style.card} onSubmit={formik.handleSubmit} autoComplete='on'>
                <img src={logo} className={style.logo} alt='casc-logo' />
                <h1>Log in</h1>
                <input
                  type='email'
                  name='email'
                  className={style.inputBox}
                  placeholder='Email'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  required
                />
                <div></div>
                <input
                  type='password'
                  name='password'
                  className={style.inputBox}
                  placeholder='Password'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  required
                />
                <div></div>
                <button type='submit' className={`${gStyle.button} ${gStyle.red}`}>
                  {status.buttonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
