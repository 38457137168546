import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const deleteBlog = createAsyncThunk('admin/deleteBlog', async (blogId, { getState }) => {
  const res = await axios({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}/admin/blogs/${blogId}`,
    headers: {
      Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
    },
  })
  return _.get(res, 'data', null)
})

export const adminFetchBlogs = createAsyncThunk(
  'university/adminFetchBlogs ',
  async (__unused, { getState }) => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/blogs`,
      headers: {
        Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
      },
    })
    return _.get(res, 'data', null)
  }
)

export const login = createAsyncThunk(
  'university/adminLogin',
  async (params, { rejectWithValue, getState }) => {
    const email = params?.email
    const password = params?.password

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/managements/login`, {
        email,
        password,
      })
      const data = _.get(res, 'data', null)
      localStorage.setItem('token', data.token)
      return data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    blogs: [],
    loading: 'idle',
    currentRequestId: undefined,
    authError: null,
    auth: null,
  },
  reducers: {
    applyFilter: (state) => state,
    logout: (state) => {
      state.auth = null
    },
  },
  extraReducers: {
    [adminFetchBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload
    },
    // [loginCheckAdmin.fulfilled]: (state, action) => {
    //   state.admin = action.payload
    // },
    [deleteBlog.fulfilled]: (state, action) => {
      state.blogs = state.blogs.filter((blog) => blog.id !== action.payload.id)
    },
    [login.fulfilled]: (state, action) => {
      state.auth = action.payload
    },
    [login.rejected]: (state, action) => {
      state.authError = action.payload
    },
  },
})

export const { logout } = adminSlice.actions
