import React, { useEffect, useState } from 'react'
import axios from 'axios'
import style from './ChineseScore.module.scss'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import adminStyles from '../AdminManageLayout.module.scss'
import _ from 'lodash'

export default function CreateChineseScore({ isEdit = false }) {
  const auth = useSelector((state) => state.admin.auth)
  const { chineseScoreId } = useParams()
  const [universities, setUniversities] = useState([''])
  const [universityNames, setUniversityNames] = useState([''])
  const [initialValues, setInitialValues] = useState({
    name: '',
    desc: '',
    level: '',
    operator: '',
    universityNames: [],
  })
  const history = useHistory()
  const [status, setStatus] = useState({})
  const { search } = useLocation()
  useEffect(() => {
    const initCreateChineseScore = async () => {
      let universitieslist = []
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/admin/universities`,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
        .then(({ data }) => {
          universitieslist = data
          setUniversities(data)
        })
        .catch((error) => {
          console.error(error)
        })
      if (isEdit) {
        await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/admin/chinese-score/edit/${search}`,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        })
          .then(({ data }) => {
            const universityNamesLoad = data?.map(
              (item) => _.find(universitieslist, { id: item.universityId })?.name
            )
            setUniversityNames(universityNamesLoad)
            setInitialValues({
              ...data[0],
              universityNames: universityNamesLoad,
              // universityName:
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
    initCreateChineseScore()
    return () => {}
  }, [auth.token, chineseScoreId, history, isEdit, search])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setStatus({
        buttonText: 'SENDING ...',
        desc: 'กำลังส่ง...',
      })
      axios({
        url: isEdit
          ? `${process.env.REACT_APP_API_URL}/admin/chinese-score/${search}`
          : `${process.env.REACT_APP_API_URL}/admin/chinese-score/new`,
        method: 'POST',
        timeout: 5000,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
        data: formik.values.universityNames.map((universityName) =>
          _.pickBy({
            ...formik.values,
            id: null,
            universityId: universities.find((university) => university.name === universityName)?.id,
          })
        ),
      })
        .then((response) => {
          setStatus({
            buttonText: 'SUCCESS',
            desc: `เสร็จสิ้น ${new Date().toLocaleString()}`,
          })
          return history.push('/admin/chinese-score')
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED') {
            return setStatus({
              buttonText: 'CANNOT CONNECT',
              desc: 'กรุณาตรวจสอบการเชื่อมต่อ',
            })
          }
          return setStatus({
            buttonText: 'NOT SUCCESS',
            desc: 'ไม่สำเร็จ',
          })
        })
    },
  })

  return (
    <React.Fragment>
      <div className={style.cover}>
        <div className={style.wrapper}>
          <div className={style.headingRow}>
            <h1>New Chinese Score</h1>
            <Link to='/admin/chinese-score' className={adminStyles.addNewBlog}>
              กลับ
            </Link>
          </div>
          <form onSubmit={formik.handleSubmit} className={style.courseForm}>
            <div className={style.coverInput}>
              <label className={style.headInput}>ชื่อ</label>
              <input
                id='name'
                name='name'
                type='text'
                placeholder='เช่น HSK '
                required
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <a> </a>
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>เลเวล</label>
              <input
                id='level'
                name='level'
                type='text'
                placeholder='เช่น A,B,C หากเป็น TOCFL 1,2,3,4,5,6 หากเป็น HSK'
                required
                onChange={formik.handleChange}
                value={formik.values.level}
              />
              <a> </a>
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>การกระทำ</label>
              <input
                id='operator'
                name='operator'
                list='oparators'
                type='text'
                placeholder='การกระทำ > < >= <= หรือ ='
                required
                onChange={formik.handleChange}
                value={formik.values.operator}
              />
              <datalist id='oparators'>
                <option value='>' />
                <option value='<' />
                <option value='>=' />
                <option value='<=' />
                <option value='=' />
              </datalist>
              <a> </a>
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>คะแนน</label>
              <input
                id='score'
                name='score'
                type='number'
                placeholder='คะแนน'
                onChange={formik.handleChange}
                value={formik.values.score}
                required
              />
              <a> </a>
            </div>
            {universityNames?.map((name, idx) => (
              <div className={style.coverInput} key={idx}>
                <label className={style.headInput}>มหาวิทยาลัย {idx + 1}</label>
                <input
                  id={`universityNames[${idx}]`}
                  name={`universityNames[${idx}]`}
                  list='universities'
                  placeholder={`เลือกมหาวิทยาลัย ${idx + 1}`}
                  onChange={formik.handleChange}
                  value={formik.values.universityNames[idx]}
                  required
                />
                <a
                  className={style.removeButton}
                  onClick={() => {
                    formik.values.universityNames.splice(idx, 1)
                    setUniversityNames([...formik.values.universityNames])
                  }}
                >
                  <span role='img' aria-label='delete' className='anticon anticon-delete'>
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='delete'
                      width='1em'
                      height='1em'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path d='M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z'></path>
                    </svg>
                  </span>
                  ลบ
                </a>
              </div>
            ))}
            <datalist
              id='universities'
              name='universities'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.universityId}
              required
            >
              {universities.map((university) => (
                <option key={university.id} value={university.name} />
              ))}
            </datalist>
            <button
              type='button'
              className={adminStyles.addNewBlog}
              onClick={() => {
                setUniversityNames((v) => [...v, ''])
              }}
            >
              + เพิ่มมหาวิทยาลัย
            </button>
            <button type='submit' className={adminStyles.addNewBlog}>
              {status?.desc || 'ยืนยัน'}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
