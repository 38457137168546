import React, { useEffect, useState } from 'react'
import axios from 'axios'
import style from './AddCountry.module.scss'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import adminStyles from '../AdminManageLayout.module.scss'
import Swal from 'sweetalert2'

export default function CreateCountry({ isEdit = false }) {
  const auth = useSelector((state) => state.admin.auth)
  const { countryId } = useParams()
  const [cities, setCities] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: '',
    cities: '',
    unit: '',
  })
  const history = useHistory()
  const [status, setStatus] = useState({})

  useEffect(() => {
    if (isEdit) {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/admin/countries/${countryId}`,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
        .then(({ data }) => {
          setInitialValues(data)
          setCities(data.cities)
          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    return () => {}
  }, [auth.token, countryId, isEdit])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setStatus({
        buttonText: 'SENDING ...',
        desc: 'กำลังส่ง...',
      })
      const duplicate = []
      const cities = values.cities
      let checked = true
      cities
        .map((city) => city.name)
        .forEach((city) => {
          if (!duplicate.includes(city)) {
            duplicate.push(city)
          } else {
            checked = false
          }
        })

      if (!checked) {
        Swal.fire({ title: 'พบชื่อเมืองซ้ำ', icon: 'error' })
        return
      }
      axios({
        url: isEdit
          ? `${process.env.REACT_APP_API_URL}/admin/countries/${countryId}`
          : `${process.env.REACT_APP_API_URL}/admin/countries/new`,
        method: 'POST',
        timeout: 5000,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
        data: values,
      })
        .then((response) => {
          setStatus({
            buttonText: 'SUCCESS',
            desc: `เสร็จสิ้น ${new Date().toLocaleString()}`,
          })
          return history.push('/admin/countries')
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED') {
            return setStatus({
              buttonText: 'CANNOT CONNECT',
              desc: 'กรุณาตรวจสอบการเชื่อมต่อ',
            })
          }
          return setStatus({
            buttonText: 'NOT SUCCESS',
            desc: 'ไม่สำเร็จ',
          })
        })
    },
  })

  return (
    <React.Fragment>
      <div className={style.cover}>
        <div className={style.wrapper}>
          <div className={style.headingRow}>
            <h1>New Country</h1>
            <Link to='/admin/countries' className={adminStyles.addNewBlog}>
              กลับ
            </Link>
          </div>
          <form onSubmit={formik.handleSubmit} className={style.courseForm}>
            <div className={style.coverInput}>
              <label className={style.headInput}>ชื่อจริง</label>
              <input
                id='name'
                name='name'
                type='text'
                placeholder='ชื่อประเทศ'
                required
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <a> </a>
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>สกุลเงิน</label>
              <input
                id='unit'
                name='unit'
                type='text'
                placeholder='กลุลเงิน'
                required
                onChange={formik.handleChange}
                value={formik.values.unit}
              />
              <a> </a>
            </div>
            {cities.map((city, index) => (
              <div className={style.coverInput} key={index}>
                <label className={style.headInput}>เมือง {index + 1}</label>
                <input
                  id={`cities-${index}`}
                  name={`cities[${index}].name`}
                  type='text'
                  placeholder={`ชื่อเมือง ${index + 1}`}
                  onChange={formik.handleChange}
                  value={formik.values.cities[index]?.name || ''}
                />
                <a
                  className={style.removeButton}
                  onClick={() => {
                    let temp = [...formik.values.cities]
                    temp.splice(index, 1)
                    setCities([...temp])
                    setInitialValues({ ...initialValues, cities: temp })
                  }}
                >
                  <span role='img' aria-label='delete' className='anticon anticon-delete'>
                    <svg
                      viewBox='64 64 896 896'
                      focusable='false'
                      data-icon='delete'
                      width='1em'
                      height='1em'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path d='M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z'></path>
                    </svg>
                  </span>
                  ลบ
                </a>
              </div>
            ))}
            <button
              type='button'
              className={adminStyles.addNewBlog}
              onClick={() => {
                setCities((v) => [
                  ...v,
                  {
                    name: '',
                  },
                ])
              }}
            >
              + เพิ่มเมือง
            </button>
            <button type='submit' className={adminStyles.addNewBlog}>
              {status?.desc || 'ยืนยัน'}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
