import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import adminStyles from './AdminManageLayout.module.scss'
import style from './AddCountry/AddCountry.module.scss'

export default function FetchQuestionnaire() {
  const auth = useSelector((state) => state.admin.auth)
  const { questionnaireId } = useParams()
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/questionnaire/${questionnaireId}`,
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        )
        setData(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchQuestionnaire()
  }, [auth.token, questionnaireId])

  return (
    <div className={adminStyles.coverLayout}>
      <form className={style.courseForm}>
        <div className={style.coverInput}>
          <label className={style.headInput}>ประเทศ</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.country || 'ไม่มี'}
            className={!data.country && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>ชื่อ</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.name || 'ไม่มี'}
            className={!data.name && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>ต้องการคำแนะนำ</label>
          <input type='text' placeholder='' disabled value={data.needGuid ? 'ต้องการ' : 'ไม่ต้องการ'} />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>อีเมลหรือไลน์</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.emailOrLine || 'ไม่มี'}
            className={!data.emailOrLine && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>หมายเลขโทรศัพท์</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.phone || 'ไม่มี'}
            className={!data.phone && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>วุฒิ</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.program || 'ไม่มี'}
            className={!data.program && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>ภาค</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.term || 'ไม่มี'}
            className={!data.term && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>เกรด</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.grade || 'ไม่มี'}
            className={!data.grade && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>คะแนนภาษาจีน</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.chineseType || 'ไม่มี'}
            className={!data.chineseType && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>ระดับ</label>
          <input type='text' placeholder='' disabled value={data.band || data.chineseScore || ''} />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>คะแนนภาษาอังกฤษ</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.englishType || 'ไม่มี'}
            className={!data.englishType && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>ทุนการศึกษา</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.scholarship || 'ไม่มี'}
            className={!data.scholarship && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>เริ่มในปี</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.atYear || 'ไม่มี'}
            className={!data.atYear && style.textred}
          />
        </div>

        <div className={style.coverInput}>
          <label className={style.headInput}>รายละเอียด</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.detail || 'ไม่มี'}
            className={!data.detail && style.textred}
          />
        </div>
        <div className={style.coverInput}>
          <label className={style.headInput}>สาขา</label>
          <input
            type='text'
            placeholder=''
            disabled
            value={data.major?.name || data.majorId || data.otherMajor || 'ไม่มี'}
          />
        </div>
      </form>
    </div>
  )
}
