import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const adminFetchCourse = createAsyncThunk(
  'courses/adminFetchCourse ',
  async (courseId, { getState }) => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/courses/${courseId}`,
      headers: {
        Authorization: 'Bearer ' + getState().admin.auth.token,
      },
    })

    return _.get(res, 'data', null)
  }
)

export const courseSlice = createSlice({
  name: 'courses',
  initialState: {
    current: {},
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  reducers: {
    applyFilter: (state) => state,
  },
  extraReducers: {
    [adminFetchCourse.fulfilled]: (state, action) => {
      state.current = action.payload
    },
  },
})
