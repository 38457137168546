import axios from 'axios'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import adminStyles from '../AdminManageLayout.module.scss'
import style from './config.module.scss'

export default function CreateConfig({ isEdit = false }) {
  const auth = useSelector((state) => state.admin.auth)
  const { configId } = useParams()
  const [initialValues, setInitialValues] = useState({
    name: '',
    value: '',
  })
  const history = useHistory()
  const [status, setStatus] = useState({})

  useEffect(() => {
    const initCreateCost = async () => {
      if (isEdit) {
        await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/admin/config/${configId}`,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        })
          .then(({ data }) => {
            setInitialValues(data)
          })
          .catch((error) => {
            console.error(error)
            Swal.fire('error', error.message, 'error')
          })
      }
    }
    initCreateCost()
    return () => {}
  }, [auth.token, configId, isEdit])

  useEffect(() => {
    if (isEdit) {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/admin/config/${configId}`,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      })
        .then(({ data }) => {
          setInitialValues(data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    return () => {}
  }, [auth.token, configId, isEdit])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setStatus({
        buttonText: 'SENDING ...',
        desc: 'กำลังส่ง...',
      })
      axios({
        url: isEdit
          ? `${process.env.REACT_APP_API_URL}/admin/config/${configId}`
          : `${process.env.REACT_APP_API_URL}/admin/config`,
        method: 'POST',
        timeout: 5000,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
        data: _.pickBy({
          ...formik.values,
        }),
      })
        .then((response) => {
          setStatus({
            buttonText: 'SUCCESS',
            desc: `เสร็จสิ้น ${new Date().toLocaleString()}`,
          })
          return history.push('/admin/config')
        })
        .catch((error) => {
          Swal.fire('Error', error.response.data.message, 'error')
          if (error.code === 'ECONNABORTED') {
            return setStatus({
              buttonText: 'CANNOT CONNECT',
              desc: 'กรุณาตรวจสอบการเชื่อมต่อ',
            })
          }
          return setStatus({
            buttonText: 'NOT SUCCESS',
            desc: 'ไม่สำเร็จ',
          })
        })
    },
  })

  return (
    <React.Fragment>
      <div className={style.cover}>
        <div className={style.wrapper}>
          <div className={style.headingRow}>
            <h1>New Setting</h1>
            <Link to='/admin/config' className={adminStyles.addNewBlog}>
              กลับ
            </Link>
          </div>
          <form onSubmit={formik.handleSubmit} className={style.courseForm}>
            <div className={style.coverInput}>
              <label className={style.headInput}>ชื่อ</label>
              <input
                id='name'
                name='name'
                type='text'
                placeholder='ชื่อการตั้งค่า'
                required
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </div>
            <div className={style.coverInput}>
              <label className={style.headInput}>ค่าการใช้งาน</label>
              <input
                id='value'
                name='value'
                type='text'
                placeholder='ค่าสำหรับใช้งาน'
                required
                onChange={formik.handleChange}
                value={formik.values.value}
              />
            </div>
            <button type='submit' className={adminStyles.addNewBlog}>
              {status?.desc || 'ยืนยัน'}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}
