import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Space } from 'antd'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import axios from 'axios'
import _ from 'lodash'
import adminStyles from './AdminManageLayout.module.scss'
import * as moment from 'moment'
function QuestionnaireList() {
  const auth = useSelector((state) => state.admin.auth)

  const [questionnaires, setQuestionnaires] = useState([])
  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/questionnaire`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        })
        const questionnaireArray = data.map((item) => ({
          ...item,
          needGuid: item.needGuid ? 'ต้องการ' : 'ไม่ต้องการ',
          majorName: item.major?.name ?? item.otherMajor,
        }))
        setQuestionnaires(questionnaireArray)
      } catch (error) {
        console.error(error)
      }
    }
    fetchQuestionnaires()
  }, [auth.token])

  const columns = [
    ...[
      ['ชื่อ', 'name'],
      ['ประเทศ', 'country'],
      ['แนะแนว', 'needGuid'],
      ['อีเมลหรือไลน์', 'emailOrLine'],
      ['หมายเลขโทรศัพท์', 'phone'],
      ['สาขาวิชา', 'majorName'],
      ['เกรด', 'grade'],
    ].map(([title, key]) => ({
      title,
      key,
      dataIndex: key,
      align: 'center',
    })),
    {
      title: 'เวลา',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => {
        const date = moment(record.createdAt)
        return date.format('DD/MM/yyyy HH:mm')
      },
    },
    {
      title: 'คำสั่ง',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size='middle'>
          <Link to={`/admin/questionnaire/${record.id}`}>
            <SearchOutlined />
            &nbsp; เรียกดู
          </Link>
          {auth.role === 'superadmin' && (
            <a
              onClick={() => {
                deleteQuestionnaire(record.id)
              }}
            >
              <DeleteOutlined />
              &nbsp; ลบ
            </a>
          )}
        </Space>
      ),
    },
  ]

  const deleteQuestionnaire = async (questionnaireId) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API_URL}/admin/questionnaire/${questionnaireId}`,
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
      data: '',
    })
    const id = _.get(result, 'data.id')
    if (id) {
      const newData = questionnaires.filter((item) => item.id !== id)
      setQuestionnaires(newData)
    }
  }

  return (
    <React.Fragment>
      <div className={adminStyles.coverLayout}>
        <div className={adminStyles.header}>
          <h1>Questionnaire</h1>
        </div>
        <Table dataSource={questionnaires} columns={columns} rowKey='id' />
      </div>
    </React.Fragment>
  )
}

export default QuestionnaireList
