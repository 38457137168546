import { Table, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import adminStyles from '../AdminManageLayout.module.scss'
import axios from 'axios'

function AddCountry() {
  const auth = useSelector((state) => state.admin.auth)

  const columns = [
    ...[
      ['ชื่อประเทศ', 'name'],
      ['เมือง', 'cities'],
      ['สกุลเงิน', 'unit'],
    ].map(([title, key]) => ({
      title,
      key,
      dataIndex: key,
      align: 'center',
    })),
    {
      title: 'คำสั่ง',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size='middle'>
          <Link to={`/admin/countries/edit/${record.id}`}>
            <EditOutlined />
            &nbsp; แก้ไข
          </Link>
          <a
            onClick={() => {
              deleteCountry(record.id)
            }}
          >
            <DeleteOutlined />
            &nbsp; ลบ
          </a>
        </Space>
      ),
    },
  ]

  const [dataArray, setDataArray] = useState([])
  const deleteCountry = async (countryId) => {
    const result = await axios({
      url: `${process.env.REACT_APP_API_URL}/admin/countries/${countryId}`,
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
      data: '',
    })
    const id = _.get(result, 'data.id')
    if (id) {
      const newData = dataArray.filter((item) => item.id !== id)
      setDataArray(newData)
    }
  }

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/admin/countries`,
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
    })
      .then(({ data }) => {
        setDataArray(data)
      })
      .catch((error) => {
        console.error(error)
      })
    return () => {}
  }, [auth.token])

  return (
    <React.Fragment>
      <div className={adminStyles.coverLayout}>
        <div className={adminStyles.header}>
          <h1>Country</h1>
          <div className={adminStyles.btnList}>
            <Link to='/admin/countries/new' className={adminStyles.addNewBlog}>
              + เพิ่ม
            </Link>
          </div>
        </div>
        <Table
          dataSource={dataArray.map(({ id, name, cities, unit }) => ({
            id,
            name,
            unit,
            cities:
              cities
                ?.map((city) => city.name)
                .join(',')
                .slice(0, 100) + '...',
          }))}
          columns={columns}
          rowKey='name'
        />
      </div>
    </React.Fragment>
  )
}

export default AddCountry
